import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { makeRequest } from '../../helpers'
import EmergencyAssistenceView from './emergency-assistance-view'

const EmergencyAssistanceController = () => {
  const [loading, setLoading] = useState(false)
  const [emergency, setEmergency] = useState({})
  const [dataFiltered, setDataFiltered] = useState([])

  const getEmergencyAssistance = async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'page',
      params: {
        slug: 'emergency-assistance',
      },
    })
      .then(({ data }) => {
        setEmergency(data)
        setDataFiltered(_.get(data, 'content[0]'))
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  const onHandelFilterByGroup = (group) => {
    const dataByFilter = _.head(_.filter(emergency.content, { slug: group }))
    setDataFiltered(dataByFilter)
  }

  useEffect(() => {
    getEmergencyAssistance()
  }, [])

  const viewProps = {
    data: emergency,
    loading,
    onHandelFilterByGroup,
    dataFiltered,
  }
  return <EmergencyAssistenceView {...viewProps} />
}

export default EmergencyAssistanceController
